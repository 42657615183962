.Block--coreRichText {
  --core-richtext-content-start-column: 1;
  --core-richtext-content-end-column: -1;
  --block-padding-top: 0;
  --block-padding-bottom: 1rem;
}

.RichText--coreRichText {
  grid-column: 1 / -1;
  background-color: var(--core-richtext-background-color);
}

.Block--coreGrid .RichText {
  grid-column: 1 / -1;
}

@media (min-width: 1280px) {
  .RichText--coreRichText {
    grid-column-start: var(--core-richtext-content-start-column, 1);
    grid-column-end: calc(var(--core-richtext-content-start-column, 1) * -1);
  }

  .CoreGrid .RichText--coreRichText {
    grid-column: 1 / -1;
  }
}
