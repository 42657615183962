.Block--coreSocialMediaShareButtons {
  --block-padding-top: 2rem;
  --block-padding-bottom: 2rem;
}

.SocialMediaShareButtons {
  grid-column: 1 / -1;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}

.SocialMediaShareButton:not(:last-child) {
  margin-right: 2rem;
}

.SocialMediaShareButton {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--block-text-color, var(--text-primary));
  cursor: pointer;
}

.SocialMediaShareButton a {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0;
  text-decoration: none;
}

.SocialMediaShareButton a:hover {
  text-decoration: underline;
}

.SocialMediaShareButton a::before {
  content: "";
  display: block;
  height: var(--icon-size);
  width: var(--icon-size);
  margin-right: 1rem;

  background: var(--background-img) center center no-repeat;
  background-size: var(--icon-size);
}

.SocialMediaShareButton--facebook {
  --background-img: url("/static/images/facebook.svg");
  --icon-size: 2rem;
}

.SocialMediaShareButton--twitter {
  --background-img: url("/static/images/twitter.svg");
  --icon-size: 2.3rem;
}

.SocialMediaShareButton--linkedIn {
  --background-img: url("/static/images/linkedin.svg");
  --icon-size: 2.2rem;

  position: relative;
  min-height: var(--icon-size);
}

.SocialMediaShareButton--linkedIn .linkedin-this {
  position: relative;
  display: flex;
  align-items: center;
}

.SocialMediaShareButton--linkedIn .IN-widget {
  text-align: left !important;
}

.SocialMediaShareButton--linkedIn button {
  height: var(--icon-size) !important;
  width: var(--icon-size) !important;

  font-size: 0 !important;
  background: var(--background-img) center center no-repeat !important;
  background-size: var(--icon-size) !important;
}

.SocialMediaShareButton--linkedIn .linkedin-this .IN-widget button:hover,
.SocialMediaShareButton--linkedIn .linkedin-this .IN-widget button:focus {
  background-color: var(--light) !important;
}

.SocialMediaShareButton--linkedIn button xdoor-icon {
  display: none !important;
}

.SocialMediaShareButton--whatsApp {
  --background-img: url("/static/images/whatsapp.svg");
  --icon-size: 2.6rem;
}

@media (min-width: 720px) {
  .SocialMediaShareButton:not(:last-child) {
    margin-right: 3.2rem;
  }

  .SocialMediaShareButton a {
    font-size: 1.4rem;
  }

  .SocialMediaShareButton--facebook {
    --icon-size: 2.4rem;
  }

  .SocialMediaShareButton--twitter {
    --icon-size: 2.7rem;
  }

  .SocialMediaShareButton--linkedIn {
    --icon-size: 2.6rem;
  }

  .SocialMediaShareButton--linkedIn button {
    background-size: 0 !important;
  }

  .SocialMediaShareButton--linkedIn .linkedin-this::before {
    content: "";
    display: block;
    height: var(--icon-size);
    width: var(--icon-size);
    margin-right: 1rem;

    background: var(--background-img) center center no-repeat;
    background-size: var(--icon-size);
  }

  .SocialMediaShareButton--linkedIn .linkedin-this::after {
    content: "LinkedIn";
    margin-left: 1rem;
    letter-spacing: 0;
  }

  .SocialMediaShareButton--linkedIn .linkedin-this:hover::after,
  .SocialMediaShareButton--linkedIn .linkedin-this:focus::after {
    text-decoration: underline;
  }

  .SocialMediaShareButton--linkedIn .linkedin-this .IN-widget button {
    content: '';
    display: block !important;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .SocialMediaShareButton--linkedIn .linkedin-this .IN-widget button:hover,
  .SocialMediaShareButton--linkedIn .linkedin-this .IN-widget button:focus {
    background-color: transparent !important;
  }

  .SocialMediaShareButton--whatsApp {
    --icon-size: 3.1rem;
  }
}

@media (min-width: 1280px) {
  .SocialMediaShareButtons {
    grid-column: 2 / -2;
  }
}
