.Block--coreHero {
  --core-hero-content-start-column: 1;
  --core-hero-content-end-column: -1;

  display: flex;
  min-height: calc(100vh - var(--site-header-size));
}

.Wrapper--coreHero {
  width: 100%;
}

.CoreHero__Content {
  grid-column: 1 / -1;

  max-width: 70rem;
  margin-top: auto;
  margin-bottom: auto;

  color: var(--block-text-color, var(--text-inverted));
  background-color: var(--core-hero-text-background-color);
}

.CoreHero__Content--withPadding {
  padding: 1.8rem;
}

.CoreHero__Heading {
  margin: 0;
  margin-bottom: 2rem;

  font-family: var(--font-primary);
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.335;
  letter-spacing: -0.01em;
}

.CoreHero__SubHeading {
  margin: 0;

  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.22;
}

.CoreHero__Links {
  --core-hero-links-spacing: 2.8rem;

  display: flex;
  flex-wrap: wrap;
  margin: -4px calc(var(--core-hero-links-spacing, 2.8rem) / -2);
  margin-top: 2.4rem;
}

.CoreHero__Link {
  margin: 4px calc(var(--core-hero-links-spacing, 2.8rem) / 2);
}

@media (min-width: 720px) {
  .CoreHero__Content {
    grid-column-start: var(--core-hero-content-start-column, 1);
    grid-column-end: calc(var(--core-hero-content-start-column, 1) * -1);
  }

  .CoreHero__Heading {
    margin-bottom: 1.2rem;

    font-size: 5.6rem;
    line-height: 1.155;
  }

  .CoreHero__SubHeading {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.335;
  }

  .CoreHero__Links {
    --core-hero-links-spacing: 3.2rem;
    margin-top: 5rem;
  }
}

/* ELEMENTS OVERRIDES */

.Button.CoreHero__Link {
  --button-text-color: var(--block-text-color);
  --button-border-color: var(--block-text-color);
  --button-highlight-border-color: var(--block-accent-color);
  --button-highlight-background-color: var(--block-accent-color);
}

/* We should remove this rule once this task: https://app.asana.com/0/1201163265716656/1202187346626804/f is done. */
.Button--secondary.CoreHero__Link {
  --button-highlight-border-color: var(--gray);
  --button-highlight-background-color: var(--gray);
}
