.Block--coreGrid {
  --max-column-size: 3;
  --block-padding-top: 3.2rem;
  --block-padding-bottom: 3.2rem;
}

.Wrapper--coreGrid {
  display: grid;
  grid-template-columns: repeat(var(--max-column-size), 1fr);
  gap: 2rem;
}

.CoreGrid__Column {
  grid-column: auto / span min(var(--column-size), var(--max-column-size));
  display: flex;
  flex-direction: column;
}

.CoreGrid__Column .Wrapper {
  padding: 0;
}

.CoreGrid__Column .Wrapper--enableGrid {
  gap: 0;
}

.CoreGrid__Column > .Block:first-child {
  padding-top: 0;
}

.CoreGrid__Column > .Block:last-child {
  padding-bottom: 0;
}

@media(min-width: 720px) {
  .Block--coreGrid {
    --max-column-size: 8;
    --block-padding-top: 4.8rem;
    --block-padding-bottom: 4.8rem;
  }

  .Wrapper--coreGrid {
    gap: 3rem;
  }
}

@media(min-width: 1024px) {
  .Block--coreGrid {
    --max-column-size: 12;
  }
}

/* ALIGNMENT */

.CoreGrid__Column--top {
  justify-content: start;
}

.CoreGrid__Column--center {
  justify-content: center;
}

.CoreGrid__Column--bottom {
  justify-content: end;
}
