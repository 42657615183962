/* LAYOUT */

.Block.Block--coreCalendar {
  --block-padding-top: 4.2rem;
  --block-padding-bottom: 4.2rem;
}

.Block__Header--coreCalendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CoreCalendar {
  --core-calendar-items: 1;
  --core-calendar-index: 0;
  --core-calendar-items-per-page: 1;
  --core-calendar-items-spacing: 3rem;
  --core-calendar-swipe-offset: 0;
  --core-calendar-item-width: max(
    100% - var(--core-calendar-items-spacing) - 10rem,
    28rem
  );

  overflow: hidden;
}

.CoreCalendar__Strip {
  display: flex;
  margin: calc(var(--core-calendar-items-spacing) / 2);
}

.CoreCalendar[data-items="1"] .CoreCalendar__Strip {
  justify-content: center;
}

.UpcomingEvent {
  width: var(--core-calendar-item-width);
}

@media (min-width: 720px) {
  .Block.Block--coreCalendar {
    --block-padding-top: 9.2rem;
    --block-padding-bottom: 3rem;
  }

  .CoreCalendar {
    --core-calendar-items-per-page: 2;
    --core-calendar-items-spacing: 3rem;
    --core-calendar-item-width: calc(
      (100%
        - (var(--core-calendar-items-spacing)
            * (var(--core-calendar-items-per-page) - 1)))
      / var(--core-calendar-items-per-page)
    );
  }
}

@media (min-width: 1024px) {
  .Block__Header.Block__Header--coreCalendar {
    margin-bottom: 6.8rem;
  }

  .CoreCalendar {
    --core-calendar-items-per-page: 3;
  }

  .CoreCalendar[data-items="2"] .CoreCalendar__Strip {
    justify-content: center;
  }
}

/* CAROUSEL */

.CoreCalendar__Strip {
  transform: translateX(
    calc(
      ((var(--core-calendar-item-width) + var(--core-calendar-items-spacing))
      * var(--core-calendar-index)
      * -1)
      + var(--core-calendar-swipe-offset) * 1px
    )
  );
  touch-action: pan-y;
}

.CoreCalendar--swiping .CoreCalendar__Strip {
  user-select: none;
}

.CoreCalendar__Buttons {
  --core-calendar-buttons-spacing: 1rem;

  display: none;
  margin: 0 calc(var(--core-calendar-buttons-spacing) / -2);
}

.CoreCalendar__Button {
  margin: 0 calc(var(--core-calendar-buttons-spacing) / 2);
}

.CoreCalendar__Button.Button {
  /* extra class selector to increase specificity over buttons.css */
  background-size: calc(var(--button-size) * 0.9);
}

.CoreCalendar__Button--previous.Button {
  background-image: url("/static/images/ic-arrow-previous.svg");
}

.CoreCalendar__Button--next.Button {
  background-image: url("/static/images/ic-arrow-next.svg");
}

@media (min-width: 720px) {
  .CoreCalendar__Buttons {
    --core-calendar-buttons-spacing: 2rem;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .CoreCalendar__Buttons {
    --core-calendar-buttons-spacing: 4rem;
  }
}

/* CAROUSEL TRANSITIONS */

.CoreCalendar:not(.CoreCalendar--swiping) .CoreCalendar__Strip {
  transition: transform 250ms ease;
}

@media (prefers-reduced-motion: reduce) {
  .CoreCalendar__Strip {
    transition: none !important;
  }
}

/* UPCOMING EVENT ITEMS */

.UpcomingEvent {
  --core-calendar-item-media-opacity: 1;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray);
  box-shadow: 0.2rem 0.1rem 2.2rem var(--dark-transparent-500);
}

.UpcomingEvent:not(:last-child) {
  margin-right: var(--core-calendar-items-spacing);
}

.UpcomingEvent--unavailable {
  --core-calendar-item-media-opacity: 0.5;
  box-shadow: none;
  cursor: not-allowed;
}

.UpcomingEvent--unavailable .UpcomingEvent__Title,
.UpcomingEvent--unavailable .UpcomingEvent__Status,
.UpcomingEvent--unavailable .UpcomingEvent__Tags,
.UpcomingEvent--unavailable .ActivitiesFeed__Item__RichText {
  opacity: var(--core-calendar-item-media-opacity, 1);
}

.UpcomingEvent__Details {
  position: relative;
  display: block;
  width: 100%;
  padding: 1.4rem;
  padding-bottom: 1.2rem;

  text-decoration: none;
}

.UpcomingEvent__Details:last-child {
  padding-top: 1.4rem;
}

.UpcomingEvent__Media {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 4 / 3;
  margin-bottom: 1rem;
  overflow: hidden;
}

.UpcomingEvent__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.UpcomingEvent__Media__Overlay {
  position: absolute;
  padding: 0.8rem 2.4rem;
  margin: 0;
  border-radius: 10rem;

  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--light);
  background-color: var(--dark-transparent-350);
}

.UpcomingEvent__Category {
  margin: 0;
  margin-bottom: 3px;

  font-size: 1.2rem;
  line-height: 1.395;
  text-transform: uppercase;
}

.UpcomingEvent__Title {
  margin: 0;
  margin-bottom: 0.4rem;

  font-family: var(--font-primary);
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.18;
  letter-spacing: -0.01em;
}

.UpcomingEvent__Ongoing {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.2rem 1.2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;

  font-family: var(--font-primary);
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  color: var(--text-primary);
  background-color: var(--secondary-light);
}

.UpcomingEvent__Ongoing::before {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  background: url("/static/images/ic-live-dark.svg") center center no-repeat;
}

.UpcomingEvent__Date,
.UpcomingEvent__Location {
  margin: 0;

  font-size: 1.6rem;
  line-height: 0.98;
  color: var(--gray-100);
}

.UpcomingEvent__Date {
  display: block;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}

.UpcomingEvent__Location {
  margin-top: 6px;
}

.UpcomingEvent__Tags {
  padding: 3rem 1.4rem;
  padding-top: 0;
}

.UpcomingEvent__Tag {
  line-height: 0.98;
  z-index: 1;
}

@media (min-width: 720px) {
  .UpcomingEvent {
    border-width: 2px;
  }

  .UpcomingEvent__Details {
    padding: 2rem;
    padding-bottom: 1.4rem;
  }

  .UpcomingEvent__Media {
    margin-bottom: 2.4rem;
  }

  .UpcomingEvent__Media__Overlay {
    display: none;
    font-size: 2.4rem;
    letter-spacing: 0.03em;
  }

  .UpcomingEvent__Category {
    margin-bottom: 6px;

    font-size: 1.6rem;
    line-height: 0.98;
  }

  .UpcomingEvent__Title {
    margin-bottom: 0.6rem;
    font-size: 2.4rem;
  }

  .UpcomingEvent__Status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .UpcomingEvent__Ongoing {
    padding: 0.4rem 1.6rem;
    margin-right: 1.6rem;
    font-size: 1.8rem;
  }

  .UpcomingEvent__Ongoing::before {
    width: 2.4rem;
    height: 2.4rem;
  }

  .UpcomingEvent__Date,
  .UpcomingEvent__Location {
    font-size: 1.8rem;
  }

  .UpcomingEvent__Location {
    margin-top: 1rem;
  }

  .UpcomingEvent__Tags {
    padding: 4rem 2rem;
    padding-top: 0;
  }
}

/* INTERACTIONS */

.UpcomingEvent {
  --core-calendar-item-highlight-scale: 1.05;
  --core-calendar-item-highlight-color: var(--primary-light);
}

.UpcomingEvent--unavailable {
  --core-calendar-item-highlight-scale: 1;
  --core-calendar-item-highlight-color: var(--text-primary);
}

.UpcomingEvent__Image {
  transition: transform 250ms ease;
}

.UpcomingEvent__Details:hover .UpcomingEvent__Image,
.UpcomingEvent__Details:focus .UpcomingEvent__Image {
  transform: scale(var(--core-calendar-item-highlight-scale, 1.05));
}

.UpcomingEvent__Details:hover .UpcomingEvent__Media__Overlay,
.UpcomingEvent__Details:focus .UpcomingEvent__Media__Overlay {
  display: block;
}

.UpcomingEvent__Title {
  transition: color 250ms ease;
}

.UpcomingEvent__Details:hover .UpcomingEvent__Title,
.UpcomingEvent__Details:focus .UpcomingEvent__Title {
  color: var(--core-calendar-item-highlight-color, var(--primary-light));
}

@media (prefers-reduced-motion: reduce) {
  .UpcomingEvent__Image {
    transition: none !important;
    transform: none !important;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .UpcomingEvent__Media::before {
    padding-top: calc(100% / 4 * 3);
  }
}
