.Block--coreFullImage {
  --block-padding-top: 0;
  --block-padding-bottom: 0;
}

.CoreFullImage__Link {
  display: block;
  overflow: hidden;
}

.CoreFullImage__Image {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}

/* STATES AND TRANSITIONS */

.CoreFullImage__Image {
  transition: transform 250ms ease;
}

.CoreFullImage__Link:hover .CoreFullImage__Image,
.CoreFullImage__Link:focus .CoreFullImage__Image {
  transform: scale(1.05);
}

.CoreImage--small {
  max-width: 119.5rem;
}

@media (prefers-reduced-motion: reduce) {
  .CoreFullImage__Image {
    transition: none !important;
    transform: none !important;
  }
}

@media (min-width: 1280px) {
  .CoreImage--medium {
    max-width: calc(100rem + (100vw - 100rem) / 2);
  }
}

@media screen and (orientation:portrait) {
  .CoreFullImage__Image--landscape {
    display: None;
  }
  .CoreFullImage__Image--portrait {
    display: auto;
  }
}

@media screen and (orientation:landscape) {
  .CoreFullImage__Image--landscape {
    display: auto;
  }
  .CoreFullImage__Image--portrait {
    display: None;
  }
}
