.Block--greetings {
  --block-padding-bottom: 12rem;
  background-color: var(--secondary-light);
}

.Block__Header--greetings {
  margin-bottom: 6rem;
}

.Block__Title--greetings {
  font-size: 7.2rem;
  line-height: 0.99;
  letter-spacing: -0.01em;
  text-align: center;
}

.GreetingsWrapper,
.RichText--greetings,
.Greetings__CTA {
  grid-column: 1 / -1;
}

.GreetingsWrapper {
  display: grid;
  gap: 2rem;
  margin-bottom: 4rem;
}

.Greetings {
  display: block;
  height: 100%;
}

.Greetings__Details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 86rem;
}

.Greetings__Media {
  aspect-ratio: 0.7;
  width: 100%;
  overflow: hidden;
}

.Greetings__Media::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, transparent 59.46%, var(--dark-transparent-350) 76.05%, var(--dark) 100%);
}

.Greetings__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.Greetings__TextOverlay {
  position: absolute;
  bottom: 0;
  padding: 2rem 1.2rem;
  color: var(--light);
}

.Greetings__Title,
.Greetings__Subtitle {
  margin: 0;
  line-height: 1.3;
}

.Greetings__Title {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.Greetings__Subtitle {
  font-size: 1rem;
  font-weight: 400;
}

.RichText--greetings {
  margin-bottom: 4.8rem;
}

.Greetings__CTA {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
}

.Greetings__CTA__Icon {
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  border-radius: 7.3rem;

  background: var(--text-primary) url("/static/images/ic-arrow-yellow.svg") center center no-repeat;
  background-size: 2.8rem;
}

.Greetings__CTA__Text {
  width: 40.8rem;
}

@media (min-width: 720px) {
  .GreetingsWrapper,
  .RichText--greetings,
  .Greetings__CTA {
    grid-column: 2 / -2;
  }

  .GreetingsWrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .GreetingsWrapper[data-items="1"] {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .GreetingsWrapper[data-items="2"] {
    grid-template-columns: 0.5fr repeat(2, 1fr) 0.5fr;
  }

  .GreetingsWrapper[data-items="1"]::before,
  .GreetingsWrapper[data-items="1"]::after,
  .GreetingsWrapper[data-items="2"]::before,
  .GreetingsWrapper[data-items="2"]::after {
    content: "";
  }
}

@media (min-width: 1280px) {
  .Greetings__TextOverlay {
    padding: 6rem 4rem;
  }

  .Greetings__Title {
    font-size: 4.6rem;
  }

  .Greetings__Subtitle {
    font-size: 2.4rem;
  }

  .RichText--greetings {
    margin-bottom: 6rem;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 0.5) {
  .Greetings__Media::before {
    padding-top: 50%;
  }
}
