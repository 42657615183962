.Block--coreBannerCarousel {
  --core-banner-carousel-items: 1;
  --core-banner-carousel-index: 0;
  --core-banner-carousel-items-per-page: 1;

  --block-padding-top: 0;
  --block-padding-bottom: 0;

  overflow: hidden;
}

.Layout--coreBannerCarousel {
  display: grid;
}

.Wrapper--coreBannerCarousel {
  display: flex;
  align-items: center;
  height: 100%;
}

.RichText--coreBannerCarousel {
  background-color: var(--core-banner-text-background-color);
}

.RichText--coreBannerCarousel > *:first-child {
  margin-top: 0;
}

.RichText--coreBannerCarousel > *:last-child {
  margin-bottom: 0;
}

.CoreBannerCarousel__Strip,
.CoreBannerCarousel__Actions {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.CoreBannerCarousel__Strip {
  display: grid;
  grid-template-columns: repeat(var(--core-banner-carousel-items), 100%);
  max-width: 100vw;
  padding: 0;

  transform: translateX(calc(var(--core-banner-carousel-index) * -100%));
}

.CoreBannerCarousel__Slide {
  display: grid;
  text-decoration: none;
}

.CoreBannerCarousel__Image,
.CoreBannerCarousel__Details {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.CoreBannerCarousel__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.CoreBannerCarousel__Details {
  padding-top: 2rem;
  padding-bottom: 8rem;
}

.CoreBannerCarousel__Content {
  max-width: 86rem;
}

.CoreBannerCarousel__Button:not(:only-child) {
  --button-text-color: var(--block-text-color);
  --button-background-color: var(--block-accent-color);

  margin-top: 3.2rem;
}

.CoreBannerCarousel__Actions {
  align-self: end;
  position: relative;
  z-index: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;

  pointer-events: none;
}

.CoreBannerCarousel__Action {
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: none;
  border-radius: 50%;

  font-size: 0;
  background: transparent center center no-repeat;
  background-size: cover;
  cursor: pointer;
  pointer-events: initial;
}

.CoreBannerCarousel__Action:hover,
.CoreBannerCarousel__Action:focus {
  background-color: var(--dark-transparent-200);
}

.CoreBannerCarousel__Action__Icon {
  display: block;
  width: 100%;
  height: 100%;

  pointer-events: none;
}

.CoreBannerCarousel__Dots {
  display: flex;
}

.CoreBannerCarousel__Dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin: 0 0.4rem;

  background-color: var(--background-primary);
}

.CoreBannerCarousel__Dot--current {
  background-color: var(--block-accent-color, var(--secondary));
}

@media (min-width: 720px) {
  .Wrapper--coreBannerCarousel {
    max-width: 162rem;
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .CoreBannerCarousel__Details {
    padding-top: 8rem;
  }

  .CoreBannerCarousel__Actions {
    align-self: stretch;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  .CoreBannerCarousel__Action {
    width: 8rem;
    height: 8rem;
  }

  .CoreBannerCarousel__Dots {
    align-self: flex-end;
  }

  .CoreBannerCarousel__Dot {
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 0.8rem;
  }
}

/* TRANSITIONS */

.CoreBannerCarousel__Strip {
  transition: transform 250ms ease;
}

@media (prefers-reduced-motion: reduce) {
  .CoreBannerCarousel__Strip {
    transition: none !important;
  }
}
