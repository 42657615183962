.CoreCaptionedGallery__Title {
  margin: 0;
  margin-top: 1.2rem;

  font-family: var(--font-primary);
  font-size: 1.8rem;
  font-weight: 500;
}

@media (min-width: 720px) {
  .CoreCaptionedGallery__Title--large {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .CoreCaptionedGallery__Title--medium {
    font-size: 2rem;
  }

  .CoreCaptionedGallery__Title--large {
    margin-top: 2rem;
    font-size: 2.2rem;
  }
}

/* STATES AND INTERACTIONS */

.CoreCaptionedGallery__Title {
  transition: color 250ms ease;
}

a.CoreGallery__Item__Details:hover .CoreCaptionedGallery__Title,
a.CoreGallery__Item__Details:focus .CoreCaptionedGallery__Title {
  color: var(--block-accent-color, var(--primary-light));
}
