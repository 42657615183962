.Wrapper--coreCtaButtonCenter {
  text-align: center;
}

.Wrapper--coreCtaButtonEnd {
  text-align: right;
}

/* ELEMENTS OVERRIDES */

.Button.CoreCtaButton__Button {
  --button-text-color: var(--block-text-color, var(--dark));
  --button-border-color: var(--block-text-color, var(--dark));
  --button-highlight-border-color: var(--block-accent-color, var(--secondary-light));
  --button-highlight-background-color: var(--block-accent-color, var(--secondary-light));
}
