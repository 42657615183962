.Block--coreDocuments {
  --core-documents-border-opacity: 0.1;
}

.CoreDocuments {
  grid-column: 1 / -1;
  display: grid;
  gap: 2rem;
}

.CoreDocuments__Item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2.4rem;

  font-size: 1.4rem;
  text-decoration: none;
}

.CoreDocuments__Item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid currentColor;
  opacity: var(--core-documents-border-opacity);
}

.CoreDocuments__Item__Icon {
  flex-shrink: 0;
  display: block;
  width: 2.4rem;
  margin-right: 0.8rem;
}

.CoreDocuments__Item__Text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Block--coreGrid .Block--coreDocuments {
  --block-padding-top: 2rem;
}

.Block--coreGrid .CoreDocuments {
  grid-column: 1 / -1;
  grid-template-columns: 1fr;
}

@media (min-width: 460px) {
  .CoreDocuments {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
}

@media (min-width: 720px) {
  .CoreDocuments {
    grid-column: 2 / -2;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .CoreDocuments__Item {
    padding: 3.8rem;
    font-size: 1.8rem;
  }

  .CoreDocuments__Item__Icon {
    width: 2.6rem;
  }
}

/* STATES AND INTERACTIONS */

.CoreDocuments__Item__Icon {
  transition: transform 250ms ease;
}

.CoreDocuments__Item:hover .CoreDocuments__Item__Icon,
.CoreDocuments__Item:focus .CoreDocuments__Item__Icon {
  transform: translateX(-30%);
}

@media (min-width: 720px) {
  .CoreDocuments__Item:hover .CoreDocuments__Item__Icon,
  .CoreDocuments__Item:focus .CoreDocuments__Item__Icon {
    transform: translateX(-50%);
  }
}

@media (prefers-reduced-motion: reduce) {
  .CoreDocuments__Item__Icon {
    transition: none !important;
    transform: none !important;
  }
}
