.Wrapper--coreInlineImageText {
  display: grid;
  align-items: center;
  gap: 3.2rem;
}

.Wrapper--coreInlineImageTextStart {
  grid-template-areas: "image";
}

.Wrapper--coreInlineImageTextEnd {
  grid-template-areas: "." "image";
}

.RichText--coreInlineImageText > *:first-child {
  margin-top: 0;
}

.RichText--coreInlineImageText > *:last-child {
  margin-bottom: 0;
}

.CoreInlineImageText__Image {
  grid-area: image;
  display: block;
  width: 100%;
  height: auto;
}

.Button.CoreInlineImageText__Button {
  --button-text-color: var(--block-text-color, var(--dark));
  --button-border-color: var(--block-text-color, var(--dark));
  --button-highlight-border-color: var(--block-accent-color, var(--secondary-light));
  --button-highlight-background-color: var(--block-accent-color, var(--secondary-light));

  margin-top: 2.4rem;
}

@media (min-width: 720px) {
  .Wrapper--coreInlineImageText {
    gap: 5.2rem;
  }

  .CoreInlineImageText__Button {
    margin-top: 3.2rem;
  }
}

@media (min-width: 1024px) {
  .Wrapper--coreInlineImageText {
    grid-template-columns: 1fr 1fr;
  }

  .Wrapper--coreInlineImageTextStart {
    grid-template-areas: "image .";
  }

  .Wrapper--coreInlineImageTextEnd {
    grid-template-areas: ". image";
  }
}
