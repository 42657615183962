/* BLOCK LAYOUT */

.CoreContactInfo {
  grid-template-areas:
    "c c c c c c c c c c c c";
  row-gap: 4.2rem;
}

.CoreContactInfo--withAddress {
  grid-template-areas:
    "c c c c c c c c c c c c"
    "a a a a a a a a a a a a";
}

.CoreContactInfo--withEmbed {
  grid-template-areas:
    "c c c c c c c c c c c c"
    "a a a a a a a a a a a a"
    "e e e e e e e e e e e e";
}

.CoreContactInfo__Group--contact {
  grid-area: c;
}

.CoreContactInfo__Group--address {
  grid-area: a;
}

.CoreContactInfo__Group--embed {
  grid-area: e;
}

@media (min-width: 720px) {
  .CoreContactInfo {
    grid-template-areas:
      "c c c c c c c c c c c c";
  }

  .CoreContactInfo--withAddress {
    grid-template-areas:
      "c c c c c c a a a a a a";
  }

  .CoreContactInfo--withEmbed {
    grid-template-areas:
      "c c c c c c a a a a a a"
      "e e e e e e e e e e e e";
  }
}

@media (min-width: 1024px) {
  .CoreContactInfo {
    grid-template-areas:
      "c c c c c c c c . . . .";
  }

  .CoreContactInfo--withAddress {
    grid-template-areas:
      "c c c c c c c . a a a a";
  }

  .CoreContactInfo--withEmbed {
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "c c c . e e e e e e e e"
      "a a a . e e e e e e e e";
  }
}

@media (min-width: 1280px) {
  .CoreContactInfo--withEmbed {
    row-gap: 8.2rem;
  }
}

/* ELEMENTS */

.CoreContactInfo__Group--embed {
  aspect-ratio: 16 / 9;
}

.CoreContactInfo__Heading {
  margin: 0;
  margin-bottom: 1.8rem;

  font-size: 2.6rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

.CoreContactInfo__Links {
  padding: 0;
  margin: 0;

  list-style: none;
}

.CoreContactInfo__Link,
.CoreContactInfo__Text {
  margin: 0;
  font-size: 1.6rem;
}

.CoreContactInfo__LinkWrapper:not(:last-child) {
  margin-bottom: 0.4rem;
}

.CoreContactInfo__Link {
  display: inline-flex;
  align-items: start;

  text-decoration: none;
  word-break: break-word;
}

.CoreContactInfo__Link:hover,
.CoreContactInfo__Link:focus {
  text-decoration: underline;
}

.CoreContactInfo__Link::before {
  content: "";
  flex-shrink: 0;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;

  background: currentColor center center no-repeat;
  background-size: 100%;
  transform: scale(0.8) translateY(-0.1rem);
  transform-origin: left center;

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.CoreContactInfo__Embed {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border: none;

  background-color: var(--light-gray);
}

@media (min-width: 720px) {
  .CoreContactInfo__Heading {
    margin-bottom: 2.2rem;
    font-size: 3.2rem;
    line-height: 1.22;
  }

  .CoreContactInfo__LinkWrapper:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  .CoreContactInfo__Link,
  .CoreContactInfo__Text {
    font-size: 2rem;
  }

  .CoreContactInfo__Link::before {
    margin-right: 1.2rem;
    transform: translateY(0.2rem);
    line-height: 1.3;
  }
}

@media (min-width: 1024px) {
  .CoreContactInfo:not(.CoreContactInfo--withEmbed) .CoreContactInfo__Links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
  }
}

/* CONTACT LINK ICONS */

.CoreContactInfo__Link::before {
  -webkit-mask-image: url("/static/images/ic-earth-light.svg");
  mask-image: url("/static/images/ic-earth-light.svg");
}

.CoreContactInfo__Link[href^="mailto:"]::before {
  -webkit-mask-image: url("/static/images/ic-email-outline-light.svg");
  mask-image: url("/static/images/ic-email-outline-light.svg");
}

.CoreContactInfo__Link[href^="tel:"]::before {
  -webkit-mask-image: url("/static/images/ic-phone-light.svg");
  mask-image: url("/static/images/ic-phone-light.svg");
}

.CoreContactInfo__Link[href*="facebook"]::before {
  -webkit-mask-image: url("/static/images/ic-facebook-light.svg");
  mask-image: url("/static/images/ic-facebook-light.svg");
}

.CoreContactInfo__Link[href*="twitter"]::before {
  -webkit-mask-image: url("/static/images/ic-twitter-light.svg");
  mask-image: url("/static/images/ic-twitter-light.svg");
}

.CoreContactInfo__Link[href*="instagram"]::before {
  -webkit-mask-image: url("/static/images/ic-instagram-light.svg");
  mask-image: url("/static/images/ic-instagram-light.svg");
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .CoreContactInfo__Group--embed::before {
    padding-top: calc(100% / 16 * 9);
  }
}
