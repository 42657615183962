.Block--corePublishDate {
  --block-padding-top: 0.4rem;
  --block-padding-bottom: 1.4rem;
}

.CorePublishDate {
  grid-column: 1 / -1;
  font-size: 1.6rem;
  color: var(--block-text-color, var(--gray-100));
}

@media (min-width: 720px) {
  .Block--corePublishDate {
    --block-padding-top: 0.8rem;
  }

  .CorePublishDate {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) {
  .CorePublishDate {
    grid-column: 2 / -2;
  }
}

