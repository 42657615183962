/* BLOCK LAYOUT */

.Block--coreTestimonial {
  --core-testimonial-items: 1;
  --core-testimonial-index: 0;
  --core-testimonial-items-per-page: 1;

  --block-padding-top: 0;
  --block-padding-bottom: 0;
}

.Wrapper--coreTestimonial {
  padding-left: 0;
  padding-right: 0;

  overflow: hidden;
}

.CoreTestimonial__Strip {
  --core-testimonial-width: calc(100% / var(--core-testimonial-items-per-page));

  display: grid;
  grid-template-columns: repeat(var(--core-testimonial-items), var(--core-testimonial-width));
  grid-template-rows: auto auto;
  grid-auto-flow: column;

  transform: translateX(calc(var(--core-testimonial-index) * var(--core-testimonial-width) * -1));
}

.CoreTestimonial__Slide {
  display: contents;
}

@media (min-width: 720px) {
  .Block--coreTestimonial {
    --core-testimonial-items-per-page: 2;
  }

  .Wrapper--coreTestimonial {
    max-width: calc(100% - 6rem);
  }
}

@media (min-width: 1024px) {
  .Block--coreTestimonial {
    --core-testimonial-items-per-page: 3;
  }
}

@media (min-width: 1500px) {
  .Wrapper--coreTestimonial {
    max-width: 144rem;
  }
}

/* ELEMENTS */

.CoreTestimonial__Message {
  padding: 8rem 2rem;
  margin: 0;

  text-align: center;
}

.CoreTestimonial__Details {
  display: flex;
  padding: 2rem;
  padding-bottom: calc(4rem + 2rem + 2rem); /* self bottom padding + actions height + actions bottom padding */
}

.CoreTestimonial__Image {
  display: block;
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 50%;
  margin-right: 2rem;
}

.CoreTestimonial__Name {
  margin: 0;
  margin-bottom: 0.4rem;

  font-size: 1.8rem;
  font-weight: 600;
}

.CoreTestimonial__Designation > * {
  margin: 0;
}

.CoreTestimonial__Actions {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  margin-top: calc((2rem + 2rem) * -1); /* height + bottom padding */

  pointer-events: none;
}

.CoreTestimonial__Action {
  width: 2rem;
  height: 2rem;
  border: none;
  margin: 0 2.5rem;

  font-size: 0;
  background: center center no-repeat;
  background-size: contain;
  cursor: pointer;
  pointer-events: initial;
}

.CoreTestimonial__Action--previous {
  background-image: url("/static/images/ic-arrow-backward-gray.svg");
}

.CoreTestimonial__Action--next {
  background-image: url("/static/images/ic-arrow-forward-gray.svg");
}

.CoreTestimonial__Dots {
  display: flex;
  margin: 0 2.5rem;
}

.CoreTestimonial__Dot {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin: 0 0.2rem;

  background-color: var(--gray);
}

.CoreTestimonial__Dot--current {
  box-shadow: 0 0 0 1px var(--block-background-color, var(--background-primary));
  background-color: var(--block-accent-color, var(--secondary-light));
}

@media (min-width: 720px) {
  .CoreTestimonial__Message {
    padding: 12rem 3.2rem;
  }

  .CoreTestimonial__Details {
    padding: 5rem 3.2rem;
    padding-bottom: calc(3rem + 2.4rem + 5rem);
  }

  .CoreTestimonial__Person {
    margin-top: 0.8rem;
  }

  .CoreTestimonial__Image {
    width: 7.5rem;
    height: 7.5rem;
  }

  .CoreTestimonial__Name {
    font-size: 2rem;
  }

  .CoreTestimonial__Actions {
    padding-bottom: 5rem;
    margin-top: calc((2.4rem + 5rem) * -1);
  }

  .CoreTestimonial__Action {
    width: 2.4rem;
    height: 2.4rem;
  }

  .CoreTestimonial__Dot {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
  }
}

@media (min-width: 1280px) {
  .CoreTestimonial__Message {
    padding: 18rem 5rem;
  }
}

/* STATES AND INTERACTIONS */

.CoreTestimonial__Message {
  border-top: 0.8rem solid transparent;
}

.CoreTestimonial__Slide:hover .CoreTestimonial__Details {
  color: var(--block-text-color, var(--text-inverted));
  background-color: var(--block-accent-color, var(--primary));
}

.CoreTestimonial__Slide:hover .CoreTestimonial__Message {
  border-top-color: var(--block-accent-color, var(--secondary-light));
}

@media (min-width: 720px) {
  .CoreTestimonial__Message {
    border-top-width: 1.4rem;
  }
}

/* TRANSITIONS */

.CoreTestimonial__Strip {
  transition: transform 250ms ease;
}

@media (prefers-reduced-motion: reduce) {
  .CoreTestimonial__Strip {
    transition: none !important;
  }
}
