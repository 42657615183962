/* BLOCK LAYOUT */

.Block--coreTiledImageMenu {
  --block-padding-top: 0;
  --block-padding-bottom: 0;
}

.Layout--coreTiledImageMenu {
  display: grid;
  gap: 0.4rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.CoreTiledImageMenu {
  aspect-ratio: 1;
}

@media (min-width: 720px) {
  .Layout--coreTiledImageMenu:not([data-items="1"]) .CoreTiledImageMenu {
    aspect-ratio: initial;
  }

  .Layout--coreTiledImageMenu[data-items="2"] {
    grid-template-columns: 1fr 1fr;
  }

  .Layout--coreTiledImageMenu[data-items="3"] {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
  }

  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(1) {
    grid-column: 1 / -1;
  }

  .Layout--coreTiledImageMenu[data-items="4"] {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
  }

  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(1),
  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(2) {
    grid-column: 1  / -1;
  }
}

@media (min-width: 1024px) {
  .CoreTiledImageMenu {
    aspect-ratio: initial;
  }

  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(1) {
    grid-column: initial;
    grid-row: 1 / -1;
  }

  .Layout--coreTiledImageMenu[data-items="4"] {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 2fr 1fr;
  }

  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(1) {
    grid-column-end: span 2;
    grid-row: 1 / -1;
  }

  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(2) {
    grid-column: 3 / span 2;
  }
}

/* ELEMENTS */

.CoreTiledImageMenu {
  display: grid;
  text-decoration: none;
}

.CoreTiledImageMenu__Content,
.CoreTiledImageMenu__Media,
.CoreTiledImageMenu__Details {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.CoreTiledImageMenu__Content {
  display: grid;
  overflow: auto;
}

.CoreTiledImageMenu__Media {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.CoreTiledImageMenu__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.CoreTiledImageMenu__Details {
  display: flex;
  align-items: flex-end;

  background-color: var(--dark-transparent-100);
}

.CoreTiledImageMenu__Text {
  margin: 0;
}

/* DETAILS */

.CoreTiledImageMenu__Details {
  padding: 2rem;
  color: var(--block-text-color, var(--text-inverted));
}

.CoreTiledImageMenu__Text {
  font-size: 2rem;
}

@media (min-width: 460px) {
  .CoreTiledImageMenu__Text {
    font-size: 2.4rem;
  }
}

@media (min-width: 720px) {
  .CoreTiledImageMenu__Details {
    padding: 3rem;
  }

  .Layout--coreTiledImageMenu[data-items="1"] .CoreTiledImageMenu__Text,
  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(1) .CoreTiledImageMenu__Text {
    font-size: 3.6rem;
  }
}

@media (min-width: 1024px) {
  .Layout--coreTiledImageMenu[data-items="1"] .CoreTiledImageMenu__Details {
    padding: 8.2rem;
  }

  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(1) .CoreTiledImageMenu__Text {
    font-size: 3.6rem;
  }
}

@media (min-width: 1280px) {
  .Layout--coreTiledImageMenu[data-items="2"] .CoreTiledImageMenu__Details,
  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(1) .CoreTiledImageMenu__Details,
  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(1) .CoreTiledImageMenu__Details {
    padding: 8.2rem;
  }

  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(2) .CoreTiledImageMenu__Details,
  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(3) .CoreTiledImageMenu__Details,
  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(2) .CoreTiledImageMenu__Details,
  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(3) .CoreTiledImageMenu__Details,
  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(4) .CoreTiledImageMenu__Details {
    padding: 5.2rem;
  }

  .Layout--coreTiledImageMenu[data-items="2"] .CoreTiledImageMenu__Text {
    font-size: 3.6rem;
  }

  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(2) .CoreTiledImageMenu__Text,
  .Layout--coreTiledImageMenu[data-items="3"] .CoreTiledImageMenu:nth-child(3) .CoreTiledImageMenu__Text,
  .Layout--coreTiledImageMenu[data-items="4"] .CoreTiledImageMenu:nth-child(2) .CoreTiledImageMenu__Text {
    font-size: 3rem;
  }
}

/* STATES AND INTERACTIONS */

.CoreTiledImageMenu__Image {
  transition: transform 250ms ease;
}

.CoreTiledImageMenu__Details {
  transform: scale(1);
}

a.CoreTiledImageMenu:hover .CoreTiledImageMenu__Image,
a.CoreTiledImageMenu:focus .CoreTiledImageMenu__Image {
  transform: scale(1.05);
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .CoreTiledImageMenu::before {
    padding-top: 100%;
  }

  @media (min-width: 720px) {
    .Layout--coreTiledImageMenu:not([data-items="1"]) .CoreTiledImageMenu__Content {
      position: relative;
    }

    .Layout--coreTiledImageMenu:not([data-items="1"]) .CoreTiledImageMenu::before {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .CoreTiledImageMenu__Content {
      position: relative;
    }

    .CoreTiledImageMenu::before {
      display: none;
    }
  }
}
