.Block.Block--eventsList,
.Block.EventsList__Block,
.EventsList__Wrapper {
  background-color: var(--gray);
}

.Block__Title.Block__Title--eventsList {
  padding-bottom: 4rem;
  margin-bottom: 3rem;
  border-bottom: 4px solid var(--dark);
  font-size: 3.2rem;
}

.Block.EventsList__Block {
  padding: 0;
}

.EventsList__Block:not(:last-child),
.EventsList__Tracks {
  border-bottom: 2px solid var(--gray-100);
}

.EventsList__Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

.EventsList__Tracks {
  padding-top: 3rem;
  padding-bottom: 1.4rem;
  margin-bottom: 1rem;
}

.EventsList__Track {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.EventsList__Item {
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.EventsList__Item:nth-child(odd) {
  grid-column-start: 1;
}

.EventsList__Item:nth-child(even) {
  grid-column-start: 2;
}

.EventsList__Item__Title,
.EventsList__Item__Time,
.EventsList__Item .RichText--scheduleAbout {
  line-height: 1.3;
}

.EventsList__Item__Title {
  padding-right: 2rem;
  margin: 0;
  font-size: 2.4rem;
}

.EventsList__Grid .EventsList__Item__Title {
  padding-right: 1rem;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}

.EventsList__Item__Type {
  display: inline-flex;
  padding: 0.4rem 0.8rem;
  border: 1.5px solid var(--secondary-light);
  transform: translateY(-8px);

  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--dark);
  background-color: var(--secondary-light);
}

.EventsList__Grid .EventsList__Item__Type {
  transform: translateY(-4px);
  font-size: 0.8rem;
}

.EventsList__Item__Time {
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.EventsList__Grid .EventsList__Item__Time {
  font-size: 1.4rem;
}

.EventsList__Item .RichText--scheduleAbout {
  margin-top: 1.8rem;
}

.EventsList__Grid .RichText--scheduleAbout {
  font-size: 1.4rem;
}

.Button.EventsList__Item__Link {
  --button-text-color: var(--dark);
  --button-border-color: var(--secondary);
  --button-background-color: var(--secondary);
  --button-highlight-text-color: var(--dark);
  --button-highlight-border-color: var(--secondary-light);
  --button-highlight-background-color: var(--secondary-light);

  margin-top: 2rem;
  font-size: 1.4rem;
  text-transform: none;
}

.EventsList__Item__Link--placeholder {
  background-color: var(--gray);
  cursor: initial;
}

.EventsList__Item__TimeColumn {
  grid-column-start: 3;
  display: none;
}

.EventsList__Item__Separator {
  display: none;
}

.EventsList__Grid .EventsList__Item__Separator:nth-child(even) {
  display: block;
  grid-column: 1 / span 2;
  border-bottom: 2px solid var(--gray-100);
}

@media (min-width: 460px) {
  .EventsList__Item__Title {
    padding-right: 3rem;
    margin: 0;
    font-size: 2.4rem;
  }

  .EventsList__Grid .EventsList__Item__Title {
    padding-right: 2rem;
    font-size: 1.6rem;
    letter-spacing: -0.01em;
  }

  .EventsList__Grid .EventsList__Item__Separator:nth-child(even) {
    grid-column: 1 / span 3;
  }
}

@media (min-width: 720px) {
  .Block__Title.Block__Title--eventsList {
    padding-bottom: 6rem;
    margin-bottom: 4rem;
    border-bottom: 5px solid var(--dark);
    font-size: 6.4rem;
  }

  .EventsList__Grid {
    grid-template-columns: repeat(2, 1fr) 0.5fr;
    column-gap: 3rem;
  }

  .EventsList__Tracks {
    padding-top: 5rem;
    padding-bottom: 1.6rem;
  }

  .EventsList__Track {
    font-size: 2rem;
    line-height: 1.06;
  }

  .EventsList__Item {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  .EventsList__Grid .EventsList__Item:last-child {
    padding-bottom: 0;
  }

  .EventsList__Item__Title {
    padding-right: 6.2rem;
    font-size: 3.2rem;
    line-height: 1.36;
  }

  .EventsList__Grid .EventsList__Item__Title {
    font-size: 2.4rem;
    line-height: 1.22;
  }

  .EventsList__Grid .EventsList__Item__Type {
    font-size: 1rem;
  }

  .EventsList__Item__Time {
    font-size: 2rem;
  }

  .EventsList__Item__Time {
    margin-top: 1.4rem;
  }

  .EventsList__Grid .EventsList__Item__Time {
    display: none;
    padding-top: 4rem;
    margin-top: 0;
    font-size: 2rem;
    line-height: 1.4;
  }

  .EventsList__Grid .EventsList__Item__TimeColumn:nth-child(odd),
  .EventsList__Grid .EventsList__Item__GridTime {
    display: block;
  }

  .EventsList__Item .RichText--scheduleAbout {
    margin-top: 2rem;
  }

  .EventsList__Grid .RichText--scheduleAbout {
    font-size: 1.8rem;
  }

  .EventsList__Item__Bookmark {
    padding: 0 3.5rem;
  }
}
