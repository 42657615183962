.Block--coreIframe {
  --block-padding-top: 0;
  --block-padding-bottom: 0;
}

.CoreIframe {
  margin-left: auto;
  margin-right: auto;
}

.CoreIframe:not(.CoreIframe--keepHeight) {
  aspect-ratio: 16 / 9;
}

.CoreIframe--small {
  max-width: 119.5rem;
}

.CoreIframe__Embed {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.CoreIframe__Embed__Iframe--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CoreIframe__Embed__Iframe--left {
  display: flex;
  justify-content: left;
}

.CoreIframe__Embed__Iframe--right {
  display: flex;
  justify-content: right;
}

@media (min-width: 1280px) {
  .CoreIframe--medium {
    max-width: calc(100rem + (100vw - 100rem) / 2);
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .CoreIframe:not(.CoreIframe--keepHeight)::before {
    padding-top: calc(100% / 16 * 9);
  }
}
