.Block--coreHeading {
  --core-heading-content-start-column: 1;
  --core-heading-content-end-column: -1;

  padding-top: 0;
  padding-bottom: 0;
}

.CoreHeading {
  grid-column: 1 / -1;
  padding-top: var(--block-padding-top);
  padding-bottom: var(--block-padding-bottom);
  margin: 0;
}

.CoreHeading--center {
  text-align: center;
}

.CoreHeading--end {
  text-align: right;
}

.CoreHeading--h1,
.CoreHeading--h2 {
  --block-padding-top: 4.2rem;
  --block-padding-bottom: 2rem;
}

.CoreHeading--h3 {
  --block-padding-top: 1.4rem;
  --block-padding-bottom: 1.4rem;
}

.CoreHeading--h4,
.CoreHeading--h5,
.CoreHeading--h6 {
  --block-padding-top: 1rem;
  --block-padding-bottom: 1rem;
}

@media (min-width: 720px) {
  .CoreHeading--h1,
  .CoreHeading--h2 {
    --block-padding-top: 6.2rem;
    --block-padding-bottom: 3.8rem;
  }
}

@media (min-width: 1280px) {
  .CoreHeading {
    grid-column-start: var(--core-heading-content-start-column, 1);
    grid-column-end: calc(var(--core-heading-content-start-column, 1) * -1);
  }
}
