/* LAYOUT */

.Block--coreGallery {
  --core-gallery-content-start-column: 1;
  --core-gallery-content-end-column: -1;

  --block-padding-top: 2.8rem;
  --block-padding-bottom: 3.2rem;
}

.CoreGallery {
  grid-column: 1 / -1;
  display: grid;
  gap: 2rem;
}

@media (min-width: 460px) {
  .CoreGallery--small {
    grid-template-columns: repeat(3, 1fr);
  }

  .CoreGallery--small[data-items="2"] {
    grid-template-columns: 0.5fr repeat(2, 1fr) 0.5fr;
  }

  .CoreGallery--medium {
    grid-template-columns: repeat(2, 1fr);
  }

  .CoreGallery--medium[data-items="1"] {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .CoreGallery--small[data-items="1"]::before,
  .CoreGallery--small[data-items="1"]::after,
  .CoreGallery--small[data-items="2"]::before,
  .CoreGallery--small[data-items="2"]::after,
  .CoreGallery--medium[data-items="1"]::before,
  .CoreGallery--medium[data-items="1"]::after {
    content: "";
  }
}

@media (min-width: 720px) {
  .CoreGallery--small {
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
  }

  .CoreGallery--small[data-items="1"] {
    grid-template-columns: 1.5fr 1fr 1.5fr;
  }

  .CoreGallery--small[data-items="3"] {
    grid-template-columns: 0.5fr repeat(3, 1fr) 0.5fr;
  }

  .CoreGallery--medium {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }

  .CoreGallery--medium[data-items="2"] {
    grid-template-columns: 0.5fr repeat(2, 1fr) 0.5fr;
  }

  .CoreGallery--large {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .CoreGallery--large[data-items="1"] {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .CoreGallery--small[data-items="1"]::before,
  .CoreGallery--small[data-items="1"]::after,
  .CoreGallery--small[data-items="2"]::before,
  .CoreGallery--small[data-items="2"]::after,
  .CoreGallery--small[data-items="3"]::before,
  .CoreGallery--small[data-items="3"]::after,
  .CoreGallery--medium[data-items="2"]::before,
  .CoreGallery--medium[data-items="2"]::after,
  .CoreGallery--large[data-items="1"]::before,
  .CoreGallery--large[data-items="1"]::after  {
    content: "";
  }
}

@media (min-width: 1024px) {
  .CoreGallery--small,
  .CoreGallery--small[data-items="3"] {
    grid-template-columns: repeat(5, 1fr);
  }

  .CoreGallery--small[data-items="1"] {
    grid-template-columns: 2fr 1fr 2fr;
  }

  .CoreGallery--small[data-items="2"] {
    grid-template-columns: 1.5fr repeat(2, 1fr) 1.5fr;
  }

  .CoreGallery--small[data-items="4"] {
    grid-template-columns: 0.5fr repeat(4, 1fr) 0.5fr;
  }

  .CoreGallery--medium {
    grid-template-columns: repeat(4, 1fr);
  }

  .CoreGallery--medium[data-items="1"] {
    grid-template-columns: 1.5fr minmax(36rem, 1fr) 1.5fr;
  }

  .CoreGallery--medium[data-items="2"] {
    grid-template-columns: repeat(4, 1fr);
  }

  .CoreGallery--medium[data-items="3"] {
    grid-template-columns: 0.5fr repeat(3, minmax(26rem, 1fr)) 0.5fr;
  }

  .CoreGallery--large {
    grid-column-start: var(--core-gallery-content-start-column, 1);
    grid-column-end: calc(var(--core-gallery-content-start-column, 1) * -1);
  }

  .CoreGallery--small[data-items="1"]::before,
  .CoreGallery--small[data-items="1"]::after,
  .CoreGallery--small[data-items="2"]::before,
  .CoreGallery--small[data-items="2"]::after,
  .CoreGallery--small[data-items="3"]::before,
  .CoreGallery--small[data-items="3"]::after,
  .CoreGallery--small[data-items="4"]::before,
  .CoreGallery--small[data-items="4"]::after,
  .CoreGallery--medium[data-items="3"]::before,
  .CoreGallery--medium[data-items="3"]::after {
    content: "";
  }
}

/* ELEMENTS */

.CoreGallery__Item__Details {
  display: block;

  text-decoration: none;
}

.CoreGallery__Item__Media {
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.CoreGallery__Item__Media--square {
  aspect-ratio: 1;
}

.CoreGallery__Item__Media--rectangle {
  aspect-ratio: 4 / 3;
}

.CoreGallery__Item__Media--small {
  padding: 2.4rem;
}

.CoreGallery__Item__Media > img,
.CoreGallery__Item__Media > iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center center;
}

.CoreGallery__Item__Media--fill > img,
.CoreGallery__Item__Media--fill > iframe {
  object-fit: cover;
}

.CoreGallery__Item__Media--fit > img,
.CoreGallery__Item__Media--fit > iframe {
  object-fit: contain;
}

.CoreGallery__Caption {
  grid-column: 1 / -1;
  margin: 0;

  font-size: 1.2rem;
}

.CoreGallery__Item--fb {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
}

.CoreGallery__Item__IFrame {
  overflow: hidden;
  border: none
}

.CoreGallery__Item__IFrame--portrait {
  height: 100%;
  max-width: 100%;
}

.CoreGallery__Item__IFrame--landscape {
  width: 100%;
}

@media (min-width: 720px) {
  .CoreGallery__Caption {
    grid-column: 2 / -2;
    font-size: 1.6rem;
  }
}

@media (min-width: 1024px) {
  .CoreGallery__Item__Media--small {
    padding: 3.2rem;
  }

  .CoreGallery__Item__Media--large {
    aspect-ratio: 16 / 9;
  }

  .CoreGallery__Caption--large {
    grid-column: 3 / -3;
  }
}

/* STATES AND INTERACTIONS */

.CoreGallery__Item__Media > img {
  transition: transform 250ms ease;
}

a.CoreGallery__Item__Details:hover .CoreGallery__Item__Media > img,
a.CoreGallery__Item__Details:focus .CoreGallery__Item__Media > img {
  transform: scale(1.05);
}

@media (prefers-reduced-motion: reduce) {
  .CoreGallery__Item__Media > img {
    transition: none !important;
    transform: none !important;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .CoreGallery__Item__Media::before {
    padding-top: calc(100% / 16 * 9);
  }

  .CoreGallery__Item--fb > iframe,
  .CoreGallery__Item__Media > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 460px) {
    .CoreGallery__Item__Media--small::before {
      padding-top: 100%;
    }

    .CoreGallery__Item__Media--medium::before {
      padding-top: calc(100% / 4 * 3);
    }
  }

  @media (min-width: 720px) {
    .CoreGallery__Item__Media--large::before {
      padding-top: calc(100% / 4 * 3);
    }
  }

  @media (min-width: 1024px) {
    .CoreGallery__Item__Media--medium::before {
      padding-top: 100%;
    }

    .CoreGallery__Item__Media--large::before {
      padding-top: calc(100% / 16 * 9);
    }
  }
}
