.Block--coreFilterTags {
  --block-padding-top: 0;
  --block-padding-bottom: 0;
}

.CoreFilterTags {
  --tags-text-size: 1.6rem;
  --tags-text-color: var(--block-background-color);
  --tags-background-color: var(--block-text-color);
  --tags-padding-horizontal: 1.8rem;
  --tags-spacing: 1.4rem;

  grid-column: 1 / -1;
  padding-top: 2rem;
  padding-bottom: 1.4rem;
  margin-top: -0.7rem;
  margin-bottom: -0.7rem;
}

@media (min-width: 1280px) {
  .CoreFilterTags {
    grid-column: 2 / -2;
  }
}
