/* LAYOUT */

.CorePressReleaseFeed {
  --press-release-feed-columns: 1;
  --press-release-feed-column-gap: 2rem;
  --press-release-feed-row-gap: 3rem;

  display: grid;
  grid-template-columns: repeat(var(--press-release-feed-columns), 1fr);
  column-gap: var(--press-release-feed-column-gap);
  row-gap: var(--press-release-feed-row-gap);
  padding-top: 2.2rem;
}

@media (min-width: 720px) {
  .CorePressReleaseFeed {
    --press-release-feed-columns: 2;
    --press-release-feed-column-gap: 3rem;
    --press-release-feed-row-gap: 4rem;
  }
}

/* PRESS RELEASE ITEMS */

.CorePressRelease__Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 3rem;

  text-decoration: none;
}

.CorePressRelease__Media {
  aspect-ratio: 16 / 9;
  margin-bottom: 1rem;
  overflow: hidden;
}

.CorePressRelease__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.CorePressRelease__Link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  font-size: 0;
  text-decoration: none;
}

.CorePressRelease__Title {
  margin: 0;

  font-family: var(--font-primary);
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.22;
  letter-spacing: -0.01em;
}

.CorePressRelease__Description {
  margin: 0;
  margin-top: 1.2rem;

  font-size: 1.8rem;
  line-height: 1.3;
}

.CorePressRelease__Tags {
  --tags-text-size: 1.4rem;
  padding-top: 2.8rem;
}

.CorePressRelease__Date {
  display: inline-flex;
  align-items: center;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 0.98;
  text-transform: uppercase;
  color: var(--gray-100);
}

.CorePressRelease__Tag {
  --tags-padding-vertical: 0.6rem;
  --tags-padding-horizontal: 1.4rem;

  z-index: 1;
}

.CorePressReleases__Empty {
  padding: 2rem 0;
  margin: 0;
  margin-bottom: 15rem;

  font-family: var(--font-primary);
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.18;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 720px) {
  .CorePressRelease__Card {
    padding-bottom: 4.8rem;
  }

  .CorePressRelease__Media {
    margin-bottom: 2.8rem;
  }

  .CorePressRelease__Title {
    font-size: 2.8rem;
  }

  .CorePressRelease__Description {
    margin-top: 1.4rem;
    font-size: 2rem;
  }

  .CorePressRelease__Tags {
    --tags-text-size: 1.8rem;
  }

  .CorePressRelease__Date {
    font-size: 1.8rem;
  }

  .CorePressReleases__Empty {
    padding: 5.2rem 0;
    font-size: 2.8rem;
  }
}

/* PRESS RELEASE SEPARATOR LINES */

.CorePressRelease {
  position: relative;
}

.CorePressRelease__Line {
  --press-release-feed-line-width: calc(
    (100% * (var(--press-release-feed-columns)))
    + (var(--press-release-feed-column-gap) * var(--press-release-feed-columns))
    - (var(--press-release-feed-column-gap))
  );

  width: var(--press-release-feed-line-width);
  border: 1px solid var(--gray);
  margin: 0;

  position: absolute;
  bottom: 0;
}

.CorePressRelease:nth-child(2n) .CorePressRelease__Line {
  right: 0;
}

.CorePressRelease:nth-child(2n+1) .CorePressRelease__Line {
  left: 0;
}

.CorePressRelease:nth-last-child(1) .CorePressRelease__Line {
  display: none;
}

@media (min-width: 720px) {
  .CorePressRelease:nth-last-child(2) .CorePressRelease__Line {
    display: none;
  }
}

/* STATES AND INTERACTIONS */

.CorePressRelease__Image {
  transition: transform 250ms ease;
}

.CorePressRelease__Title {
  transition: color 250ms ease;
}

.CorePressRelease__Card:hover .CorePressRelease__Image,
.CorePressRelease__Card:focus-within .CorePressRelease__Image {
  transform: scale(1.05);
}

.CorePressRelease__Card:hover .CorePressRelease__Title,
.CorePressRelease__Card:focus-within .CorePressRelease__Title {
  color: var(--primary-light);
}

@media (prefers-reduced-motion: reduce) {
  .CorePressRelease__Image {
    transition: none !important;
    transform: none !important;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .CorePressRelease__Media::before {
    padding-top: calc(100% / 16 * 9);
  }
}


.Block.Block--corePressRoomList {
  --block-padding-top: 4.2rem;
  --block-padding-bottom: 4.2rem;
}

.Block__Header--corePressRoomList {
  margin-bottom: 3.8rem;
}

.Block__Title--corePressRoomList {
  line-height: 1.395;
}

@media (min-width: 720px) {
  .Block.Block--corePressRoomList {
    --block-padding-top: 6.2rem;
    --block-padding-bottom: 4.2rem;
  }

  .Block__Header.Block__Header--corePressRoomList {
    margin-bottom: 6.8rem;
  }

  .Block__Title--corePressRoomList {
    line-height: 1.22;
  }
}
